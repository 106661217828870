<template>
  <div class="smart-farm">
    <!-- 导航栏 -->
    <Nav :isHome="false"></Nav>
    <CommonNav pageName="爱扣钉—IT培训教育"></CommonNav>
    <section class="smart-farm-bghead">
      <img class="smart-farm-bg" src="../../assets/images/code/bg.png" alt="">
    </section>
    <section class="smart-farm-container">
      <p class="smart-farm-head">
        爱扣钉隶属于四福科技，是<strong>黑龙江省专业的IT培训机构，致力于培养高端的IT人才</strong>，主要开展Web前端和Java后端等方向的培训，我们以完善的课程体系，全新的软件项目，强大的师资团队，雄厚的公司背景作为坚强的后盾，成为进入大型互联网公司的加油站。爱扣钉紧随科技的步伐，不断的更迭创新，打造覆盖 IT 全产业链的职业课程版图，不断更新课程，实时挖掘企业需要人才类型，做到培训出来的学员技术能够即刻上岗操作，实战式教学更是爱扣钉一直以来的教学理念之一，理论与实践相结合才是教学的重中之重。爱扣钉不仅仅包括课程教学，而是从招生、学员服务、在线教学、学员与老师实时沟通互动到学习平台的保障，学员就业技巧的传递，以及推荐就业在内的整套服务体系的融合并重，一体化的学员管理、全流程的品保服务，解决了学员课程从学习到就业的一系列难题。
      </p>
    </section>
     <!-- 底部 -->
     <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
import CommonNav from '@/components/commonNav.vue'
import Footer from '@/components/footer.vue'

export default {
  data() {
    return {

    }
  },
  components: {
    Nav,
    Footer,
    CommonNav
  },
  beforeDestroy(){
    window.scrollTo(0,0)
  }
}
</script>

<style lang="scss" scoped>
.smart-farm{
  width: 100vw;
  padding-top: 120px;
}
.smart-farm-bg {
  width: 100%;
  height: 100%;
}
.smart-farm-container{
  width: 68.9%;
  max-width: 1057px;
  margin: 105px auto 142px;
  font-size: 22px;
  color: #808080;
  line-height: 2;
  text-align: left;
}
.smart-farm-head{
  text-align: justify;
  /*兼容ie*/
  text-justify: distribute-all-lines;
}

</style>